export const PROMOTION_TYPE_LIST = [
    {id: 1, label: 'Price', value: 'price'},
    {id: 2, label: 'Percent', value: 'percent'},
    {id: 3, label: 'Free Delivery', value: 'free_delivery'},
];

export const PROMOTION_RULE_TYPE_LIST = [
    {id: 1, label: 'Min items', value: 'min_items'},
    {id: 2, label: 'Min orders', value: 'min_orders'},
    {id: 3, label: 'Max deliveries', value: 'max_deliveries'},

];
