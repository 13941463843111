<template>
  <div class="ml-2 cursor-pointer" @click="setSort()">
    <DynamicIconSolid
      icon="ChevronUpIcon"
      class="h-4 w-4 text-gray-400 -mb-2"
      :class="{ 'text-blue-600': currentValue == 'asc' && currentSort }"
      aria-hidden="true"
    />
    <DynamicIconSolid
      icon="ChevronDownIcon"
      class="h-4 w-4 text-gray-400 -mt-2"
      :class="{ 'text-blue-600': currentValue == 'desc' && currentSort }"
      aria-hidden="true"
    />
  </div>
</template>

<script>
import { ref } from 'vue';
export default {
  name: 'Sort',
  props: {
    currentSort: {
    required: false,
    },
    keyValue: {
      type: String,
      required: true,
    },
  },
  emits: ['valueChanges'],
  setup(props, context) {
    const SORT = [null, 'desc', 'asc'];
    let index = 0;
    const currentValue = ref(SORT[0]);
    function setSort() {
      if (index >= SORT.length - 1) {
        index = 0;
      } else {
        index = index + 1;
      }
      currentValue.value = SORT[index];
      if (currentValue.value != null) {
        context.emit('valueChanges', {
          sort: [`${props.keyValue}.${currentValue.value}`],
          key: props.keyValue,
        });
      } else {
        context.emit('valueChanges', {
          sort: null,
          key: props.keyValue,
        });
      }
    }
    return {
      currentValue,
      setSort,
    };
  },
};
</script>
